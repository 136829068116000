import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Row, Col } from 'antd';
import Img from "gatsby-image";

const Foci = () => {
    const data = useStaticQuery(graphql`
    {
        allAirtable(filter: {table: {eq: "foci"}}) {
            edges {
                node {
                    data {
                        postMarkdown {
                            childMarkdownRemark {
                                html
                                timeToRead
                            }
                        }
                        slug
                        title
                        snippet
                        image {
                            id
                            localFiles {
                              childImageSharp {
                                fluid(jpegProgressive: true, maxWidth: 400) {
                                    ...GatsbyImageSharpFluid
                                }
                              }
                            }
                          }
                    }
                }
            }
        }
    }
    `)
return (
    <Row className='foci' id="foci">
    {
        data.allAirtable.edges.map(focus =>  {
            const focusPath = `/lab_report/${focus.node.data.slug}`;
            return (
                <Col xs={24} lg={12} xl={8} key={focus.node.data.slug} className='focus'>
                <Link to={focusPath}>
                    <Img fluid={focus.node.data.image.localFiles[0].childImageSharp.fluid} />
                    <h2>{focus.node.data.title}</h2>
                    <div className='focus_content' dangerouslySetInnerHTML={{ __html: focus.node.data.snippet }} /> 
                </Link>
                </Col>
                )
            })
        }
    </Row>
)}
    
    export default Foci